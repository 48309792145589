<template>
  <div class="profile">
    <template v-if="!isSharedProfileView">
      <mobile-header></mobile-header>
      <the-header></the-header>
    </template>
    <template v-else>
      <share-profile-header></share-profile-header>
    </template>
    <router-view></router-view>
  </div>
</template>

<script>
import theHeader from "../components/layout/ProfilePageHeader.vue";
import MobileHeader from "../components/layout/MobileHeader.vue";
import shareProfileHeader from "../components/layout/ShareProfileHeader.vue";

export default {
  components: {
    theHeader,
    MobileHeader,
    shareProfileHeader
  },
  data: () =>({
    isSharedProfileView: false
  }),
  mounted() {
    this.getUserInformation();
  },
  watch:{
    '$route':{
      handler(to, from){
        this.isSharedProfileView  = to.name === 'share-user-profile' ? true : false
      }, immediate: true
    },
    async isSharedProfileView(val){
      // if (!val){
        this.getUserInformation()
      // }
    }
  },
  methods: {
    async getUserInformation() {
      // const payload = {
      //   user_id: this.$route.params.id,
      // };
      const payload = {
        username: this.$route.params?.username ?? null
      }
      try {
        // await this.$store.dispatch("profile/getArticleNotificationsCount");
        await this.$store.dispatch("profile/fetchUserProfile", payload);
        this.$store.dispatch("profile/setConnectionRequestButtonSkeleton");
        await this.$store.dispatch("profile/loadExperiences");
        await this.$store.dispatch("profile/getAddedLanguages");
        if (!this.isSharedProfileView){
          await this.$store.dispatch("profile/fetchPhotoResume");
          await this.$store.dispatch("profile/handleConnctionRequest");
        }
        
      } catch (err) {
        console.log(err);
      }
    },
  }
};
</script>

<style lang="scss">
.profile {
  height: 100%;

  .header {
    background-color: $color-white;

    .mobile-navigation {
      &__hamburger {
        cursor: pointer;
        .line {
          background-color: $color-black;
        }
      }
    }
  }
  .profile-page-header-container {
    display: block;
    @include respond(phone-x-small) {
      display: none;
    }
  }
  .mobile-navbar-new {
    display: none;
    @include respond(phone-x-small) {
      display: block;
    }
  }
}
</style>

<template>
  <div class="share-profile-header">
    <div class="share-profile-header__logo">
      <img
        src="@/assets/images/Amblem2@2x.webp"
        alt="Logo"
        class="share-profile-header__logo--logo "
      />
      <h1 class="share-profile-header__logo--name">Glocal</h1>
    </div>
    <div class="share-profile-header__buttons">
      <button
        class="share-profile-header__buttons--btn login-btn-hover color"
        @click="showAccessModal('login')"
      >
        Access Glocal
      </button>
    </div>
  </div>
  <subscription-modal
    :visible="visibleAccessModal"
    :type="modalType"
    @closeAccessModal="closeAccessModal"
    @showForgotPasswordModal="showForgotModalPass"
    @showOTPVerificationModal="
      () => {
        this.OtpVerificationModalVisible = true;
      }
    "
  ></subscription-modal>
  <student-subscription
    :visible="visibleStudentSubscriptions"
    @closeAccessModal="closeAccessModal"
  >
  </student-subscription>
  <forgot-password-modal
    :visible="forgotModalVisible"
    @cancel="forgotModalVisible = false"
    :footer="null"
  >
  </forgot-password-modal>
  <OtpVerificationModal
    :visible="OtpVerificationModalVisible"
    @close="OtpVerificationModalVisible = false"
  />
</template>

<script>
import SubscriptionModal from "../../components/Auth/AccessModal.vue";
import StudentSubscription from "../../components/Auth/StudentSubscriptions.vue";
import ForgotPasswordModal from "../Auth/ForgotPasswordModal.vue";
import OtpVerificationModal from "../Auth/OtpVerificationModal.vue";

export default {
  components: {
    SubscriptionModal,
    StudentSubscription,
    ForgotPasswordModal,
    OtpVerificationModal,
  },
  data() {
    return {
      visibleAccessModal: false,
      modalType: "",
      visibleStudentSubscriptions: false,
      forgotModalVisible: false,
      OtpVerificationModalVisible: false,
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        if (this.$route.path === "/student-sign-up" || this.$route.path === "/student-sign-up/") {
          this.visibleStudentSubscriptions = true;
        }
      },
    },
  },
  methods: {
    showAccessModal(val) {
      this.modalType = val;
    this.visibleAccessModal = true;
    },
    closeAccessModal() {
      this.visibleAccessModal = false;
      this.modalType = "";
    },
    showForgotModalPass() {
      this.forgotModalVisible = true;
      this.closeAccessModal();
    },
  },
};
</script>

<style lang="scss" scoped>
.share-profile-header {
  width: 100%;
  position: fixed;
  top: 0;
  height: 7.93rem;
  padding: 0 4rem;
  z-index: 444;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $color-white;
  &__logo {
    display: flex;
    align-items: center;
    &--logo {
      width: 3rem;
      height: auto;
    }
    &--name {
      color: $color-black;
      margin: 0 0 0 1rem;
      font-size: 2.6rem;
      font-family: $font-primary-bold;
      line-height: normal;
    }
  }
  &__buttons {
    &--btn {
      background-color: #688bff;
      border: none;
      padding: 1.2rem 2rem;
      font-size: 1.6rem;
      font-family: $font-primary-medium;
      color: $color-white;
      line-height: normal;
      cursor: pointer;
      margin: 0;
      display: block;
      border-radius: 0.7rem !important;
    }
    .login-btn-hover {
      background-size: 300% 100%;
      moz-transition: all 0.5s ease-in-out;
      -o-transition: all 0.5s ease-in-out;
      -webkit-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out;
    }
    .login-btn-hover:hover {
      background-position: 100% 0;
      moz-transition: all 0.5s ease-in-out;
      -o-transition: all 0.5s ease-in-out;
      -webkit-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out;
    }
    .login-btn-hover.color {
      background-image: linear-gradient(
        to right,
        #3660ec,
        $color-primary,
        $color-primary,
        #688bff
      );
    }
  }
}
</style>
